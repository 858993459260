import React, { Component } from "react";
import { List, Card } from "antd";
import {
  BookOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  ContactsOutlined,
  MessageOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const data = [
  {
    title: (
      <span>
        <CalendarOutlined /> Rich Task Entries
      </span>
    ),
    content:
      "Our calendar is capable of creating highly detailed task entries - in addition you can attach files and images to tasks",
    url: "/dayviewer.com",
  },
  {
    title: (
      <span>
        <MessageOutlined /> Task List Interface
      </span>
    ),
    content:
      "Super convenient list of all your tasks in one place, view what needs to be done, by when - what's' overdue and if you have a team, see who is assigned to it - and more!",
  },
  {
    title: (
      <span>
        <CheckSquareOutlined /> Create Task Kanban Boards
      </span>
    ),
    content:
      "Organize your tasks into columns which you can then shift around by dragging and dropping - useful for placing tasks into other categories like difficulty or just organize by type.",
  },
];

class TaskMangementFeatureGrid extends Component {
  render() {
    return (
      <Card className="feature-grid-background" bordered={false}>
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 3,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.title}
                // bordered={false}
                className="feature-grid-cards"
              >
                {item.content}{" "}
                {/* <p>
                  <a href={item.url}>Screencast</a>
                </p> */}
              </Card>
            </List.Item>
          )}
        />
      </Card>
    );
  }
}

export default TaskMangementFeatureGrid;

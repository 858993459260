import React from "react";
import { Link } from "gatsby";
import {
  Layout,
  Row,
  Col,
  BackTop,
  Button,
  Popover,
  Divider,
  Modal,
  Image,
} from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import LandingTeamSlider from "../../components/landingPages/landingTeamSlider";
import TaskManagementFeatureGrid from "../../components/landingPages/taskManagementFeatureGrid";
import { TeamPlanListForPopUp } from "../../components/plans/plans";

const { Content } = Layout;

class TaskManagementLanding extends React.Component {
  state = {
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
  };
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }

  setModal3Visible(modal3Visible) {
    this.setState({ modal3Visible });
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="DayViewer Task Manager - Easy To Use yet Powerful Online Task Management System"
          />
          <title>DayViewer Task Management System</title>
        </Helmet>
        <Layout>
          <CommonHeader />
        </Layout>
        <Layout style={{ minHeight: "100vh" }}>
          <BackTop />

          <Content className="homepage-background">
            <Row>
              <div className="homepage-banner">
                <Col lg={12} xs={24}>
                  <div className="homepage-jumbotron">
                    <div className="homepage-headlines">
                      <h1>Easy To Use Online Task Management System</h1>
                      <h2>
                        Free Online Task Management Planner System Built In
                      </h2>

                      <div style={{ paddingTop: 20 }}>
                        <p>
                          A solid, reliable task management system built in to
                          help you organize and plan tasks. Use to track
                          progress, organize work and highlight what is
                          important for you to get done. For businesses, our
                          Team task management around a central calendar planner
                          can really boost your productivity and increase
                          profitability - DayViewer has task management
                          integrated directly into the calendar system.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button className="homepage-signup-btn">
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://app.dayviewer.com/auth/signup",
                            "_self"
                          )
                        }
                      >
                        Sign Up Today - Free To Get Started
                      </a>
                    </Button>
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  {/* <LandingTeamSlider /> */}
                  <video
                    preload="none"
                    controls="controls"
                    poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                    muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="DayViewer Online Weekly Planner"
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/FastPlannerAd-final.mp4"
                  />
                </Col>
              </div>
            </Row>

            <Row>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "5em" }}
              >
                <Col lg={12} xs={24}>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Image
                      // className="centered-and-cropped position-centerquarter-lefttop"
                      // width="1200"
                      // height="600"
                      src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/task-list-planner-home.png"
                      //src="https://via.placeholder.com/150"
                      alt="task management calendar"
                      title="task management calendar"
                      height="100%"
                      width="100%"
                    />
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  <div style={{ padding: 20 }}>
                    <div>
                      <h1>Online Task Planner System</h1>

                      <p>
                        If you have similar tasks happening every week, you can
                        use our handy Fast Planner system, which allows you to
                        create a template of your task or job. Prepare the
                        information you need then schedule whenever you need to!
                      </p>
                      <p>
                        Create subtasks when adding tasks with checklists - the
                        checklist then takes over the progress percentage so
                        each task can have stages of completion as per your
                        sub-task structure.
                      </p>
                      <p>
                        Keep progress updated to give yourself a chance to plan
                        - a clear visual indicator of task progress in your task
                        list.
                      </p>
                      <p>
                        Get a dashboard overview of everything going on, overdue
                        tasks and high priority tasks.
                      </p>
                      <p>
                        Red-green automatic color highlighting for tasks that
                        have gone overdue and tasks completed (resp.). Some
                        stats to show on time completion tasks (optional on
                        dashboard)
                      </p>
                      <p>
                        DayViewer is one of the few services online that has a
                        multitude of planner views (timeline, calendar, tables,
                        cards). If you need more than a spreadsheet or todo list
                        app to plan your tasks, then consider adopting DayViewer
                        as your all in one task management app.
                      </p>
                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Sign Up - It's Free To Get Started
                          </a>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <div
                className="homepage-jumbotron"
                style={{ textAlign: "left", padding: "5em" }}
              >
                <Row>
                  <Col lg={12} xs={0}>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <h2>How To Manage Tasks Effectively</h2>
                      <div style={{ paddingRight: 20 }}>
                        <ul>
                          <li>
                            List the tasks you need to do throughout the week
                            then prioritize them - at least with a "high" for
                            your more urgent tasks. you can use something like a
                            todo list to do this. If you want to you can also
                            add a difficulty rating.
                          </li>
                          <li>
                            Do your tasks need anything externally? such as
                            materials or a helping hand from someone or another
                            business? If so you may need to make the necessary
                            preparations.
                          </li>
                          <li>
                            Set estimated times, how long do you think it would
                            take to complete each task? 10 minutes? 3 days? and
                            remember to think of unexpected things that might
                            crop up to delay your progress or throw you off
                            track.
                          </li>
                          <li>
                            Subtasks - for bigger tasks, break them down into
                            more manageable bite-sized subtasks. It's also a
                            great way to measure your progress by using subtasks
                            as markers. This can be done in DayViewer by using
                            checklists on each task.
                          </li>
                          <li>
                            Review your tasks list - do you really need to spend
                            time on certain tasks? by writing things down, you
                            can visually evaluate whether you really need to get
                            that task done today or if at all - you will be
                            surprised how much stress and time that would be
                            saved by reducing unnecessary tasks by evaluation.
                          </li>
                          <li>
                            Task management is a skill that can be learned, and
                            the more you do it, the more effective you become.
                            The trick is just to start writing things down. We
                            suggest you try DayViewer to get going with planning
                            your tasks and see how you feel it affects your
                            performance over the space of 2 weeks or so.
                          </li>
                        </ul>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Start Managing Your Tasks Today
                          </a>
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={24}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <picture>
                        <source
                          type="image/webp"
                          srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.webp"
                        ></source>
                        <source
                          type="image/png"
                          srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.png"
                        ></source>
                        <Image
                          src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.png"
                          height="100%"
                          width="100%"
                          alt="Online Calendar"
                          title="Online Calendar"
                        />
                      </picture>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <Row>
              <TaskManagementFeatureGrid />
            </Row>
          </Content>
        </Layout>
        <Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default TaskManagementLanding;

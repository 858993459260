import React, { Component } from "react";
import { Card } from "antd";
import Slider from "react-slick";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        ...style,
        display: "block",
        height: "100px",
        right: "-25px",
        position: "absolute",
        top: "30%",
        padding: "3px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <RightOutlined style={{ marginTop: "38px" }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        ...style,
        display: "block",
        height: "100px",
        left: "-25px",
        position: "absolute",
        top: "30%",
        padding: "3px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <LeftOutlined style={{ marginTop: "38px" }} />
    </div>
  );
}

class LandingTeamSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <Card
        style={{
          marginTop: 10,
          marginBottom: 10,
          padding: 10,
          width: "100%",
          background: "#ffffff",
          border: "none",
        }}
      >
        <div>
          <Slider {...settings}>
            <div>
              <img
                src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-calendar.png"
                alt="team calendar"
                title="team calendar"
              />
              <h6>
                Online Team Calendar - Flexible Shared Calendar Everyone Works
                Around
              </h6>
            </div>
            <div>
              <img
                src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-schedule.png"
                alt="work scheduler"
                title="work scheduler"
              />
              <h6>Team Dashboard - At-A-Glance Status, Schedule and Stats.</h6>
            </div>
            <div>
              <img
                src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-labels.png"
                alt="online team calendar"
                title="online team calendar"
              />
              <h6>
                Labels Manager - Clicking On A Label Filters All It's Entries On
                One Page.
              </h6>
            </div>
            <div>
              <img
                src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-table.png"
                alt="online team calendar"
                title="online team calendar"
              />
              <h6>
                Team Tasks Table - All Team Tasks In One Table. Filterable,
                Sortable &amp; Clear.
              </h6>
            </div>
            <div>
              <img
                src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-task-cards.png"
                alt="online team calendar"
                title="online team calendar"
              />
              <h6>
                Task Cards - Easy To Check All Tasks Complete, In Progress and
                Not Yet Started
              </h6>
            </div>
            <div>
              <img
                src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-comments.png"
                alt="online team calendar"
                title="online team calendar"
              />
              <h6>
                Entry Comments - Keep Chat Focussed On The Task Or Any Entry.
                Direct &amp; Efficient.
              </h6>
            </div>
          </Slider>
        </div>
      </Card>
    );
  }
}

export default LandingTeamSlider;
